import React from 'react';
import BackgroundImg from 'gatsby-background-image';
//Read more about gatsby-background-image here: https://www.gatsbyjs.com/plugins/gatsby-background-image/
import PropTypes from 'prop-types';

const BackgroundImage = ({
    alt,
    backgroundColor,
    className,
    crossOrigin,
    draggable,
    durationFadeIn,
    fadeIn,
    fixed,
    fluid,
    itemProp,
    loading,
    objectFit,
    objectPosition,
    onError,
    onLoad,
    onStartLoad,
    style,
    tag,
    title,
    children
}) => {
    return (
        <BackgroundImg
            alt={alt}
            backgroundColor={backgroundColor}
            className={className}
            crossOrigin={crossOrigin}
            draggable={draggable}
            durationFadeIn={durationFadeIn}
            fadeIn={fadeIn}
            fixed={fixed}
            fluid={fluid}
            itemProp={itemProp}
            loading={loading}
            objectFit={objectFit}
            objectPosition={objectPosition}
            style={style}
            tag={tag}
            title={title}
            onError={onError}
            onLoad={onLoad}
            onStartLoad={onStartLoad}
        >
            {children}
        </BackgroundImg>
    );
};

//Read more about gatsby-background-image props here: https://www.gatsbyjs.com/plugins/gatsby-background-image/
BackgroundImage.propTypes = {
    alt: PropTypes.string, // Passed to the img element. Defaults to an empty string alt=""
    backgroundColor: PropTypes.oneOfType([
        // Set a colored background placeholder. If true, uses “lightgray” for the color.
        PropTypes.string,
        PropTypes.bool,
    ]),
    children: PropTypes.object, // JSX Object
    className: PropTypes.string, //Passed to the wrapper element.
    crossOrigin: PropTypes.string, // Passed to the img element
    draggable: PropTypes.bool, // Set img tag draggable true/false,
    durationFadeIn: PropTypes.number, // fading duration is set up to 500ms by default
    fadeIn: PropTypes.oneOfType([
        PropTypes.bool,
        PropTypes.string,
    ]), //Defaults to fading in the image on load, may be forced by 'soft' or disabled with false
    fixed: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.arrayOf([
            PropTypes.object
        ])
    ]),
    fluid: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.arrayOf([
            PropTypes.object
        ])
    ]),
    itemProp: PropTypes.string, // Add an itemprop schema.org structured data attribute on the image.
    loading: PropTypes.string, // lazy/eager/auto (defaults to lazy)
    objectFit: PropTypes.string, // Default to cover, use this, not CSS for IE11 compat
    objectPosition: PropTypes.string, // Defaults to 50% 50%, needed for object-fit cover IE
    onError: PropTypes.func, // A callback that is called when the image fails to load.
    onLoad: PropTypes.func, // A callback that is called when the full-size image has loaded.
    onStartLoad: PropTypes.func, // A callback that is called when the full-size image starts loading, it gets the parameter { wasCached: boolean } provided.
    style: PropTypes.object, // Spread into the default styles of the wrapper element
    tag: PropTypes.string, // Which HTML tag to use for wrapping elements. Defaults to div.
    title: PropTypes.string, // Passed to the img element
};

BackgroundImage.defaultProps = {
    alt: null,
    backgroundColor: null,
    children: {},
    className: null,
    crossOrigin: null,
    draggable: null,
    durationFadeIn: null,
    fadeIn: null,
    fixed: null,
    fluid: null,
    itemProp: null,
    loading: null,
    objectFit: null,
    objectPosition: null,
    onError: null,
    onLoad: null,
    onStartLoad: null,
    style: null,
    tag: null,
    title: null,
};

export default BackgroundImage;
